<!--设备模块--趋势组信息页-->
<template>
  <div id="controllGroupTrend" ref="controllGroupTrend">    
    <div class="aside">  
      <div class="top-left">
        <a-tooltip>
          <template slot="title">
            {{$t('lightRunTimetable.a33')}}
          </template>
          <a-icon class= "icon" type="plus-square" @click="handleClickAdd" /> 
        </a-tooltip> 
        <a-tooltip>
          <template slot="title">
            {{$t('lightRunTimetable.a35')}}
          </template>
          <a-icon class= "icon" type="edit" @click="handleClickEdit" />  
        </a-tooltip>
        <a-tooltip>
          <template slot="title">
            {{$t('lightRunTimetable.a34')}}
          </template>
          <a-popconfirm 
            :title="title" 
            :ok-text="confirmText" 
            :cancel-text="cancelText"
            @confirm="confirm"
            @cancel="cancel">
            <a-icon  class= "icon" type="delete"  /> 
          </a-popconfirm>   
        </a-tooltip>          
      </div>           
      <a-spin :spinning="spinning" style="width: 100%; height: 100%">               
        <my-navder
          :list="list"
          @select="getMsgFormSon"
          :style="style"
        ></my-navder>
      </a-spin>           
    </div> 
    <a-modal 
        v-model="visible" 
        :title="modalName" 
        width=300px           
        @ok="handleOk"
      >
        <span>{{$t('timeTrend.a1')}}</span>
        <a-input v-model="trendGroupName"></a-input>
    </a-modal>    
    <div class="right">
      <a-spin :spinning="loading" class="main-spinning" v-if="id">
        <div class="main">
          <my-headertitle>{{$t("timeTrend.a1")}}</my-headertitle>
          <div class="info">
            <span>{{ nodeParentName }}：{{ nodeName }}</span>
            <div>
              <span>{{$t("timeTrend.a2")}}</span>
              <span>{{ nodeId }}</span>
            </div>
          </div>
          <my-tabletitle>{{$t("timeTrend.a3")}}</my-tabletitle>
          <my-table :data="infoList"></my-table>
        </div>
      </a-spin>
      <div class="main-show" v-else>
        <a-icon type="select" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{$t("timeTrend.a4")}}</span>
      </div>
      <div class="footer" v-if="id">
        <a-button type="primary" @click="toTmEditorIn">{{$t("timeTrend.a5")}}</a-button>
      </div>      
    </div>  
    
  </div>
</template>

<script>
import tabletitle from "../../../components/Title/tabletitle";
import navdar from "../../../components/Navdar/vertical";
import headerTitle from "../../../components/Title/headerTitle";
import deviceTrendTable from "./deviceTrendTable";

import { lightTimetableListKT,getTrendDeviceInfo,updateScheduleName,
        insertTrendGrBySite,updateTrendGrBySite,deleteTrendGrBySite } from "../../../api/device";
import select from "../../../components/Select/select";

export default {
  data() {
    return {
      list: [],
      arr: [],
      pos: 0,
      spinning: false,
      id: undefined,//包含类型和时间表ID
      loading: false,
      destroyOnClose:true,
      siteId: this.$route.query.id,
      schType: 9,
      singnalType:19,
      nodeId: undefined,//树节点ID
      nodeName: undefined,//树节点名称
      nodeParentName: undefined,//父节点名称
      infoList:[],
      visible: false,      
      state: false,
      loadingOK: false,
      trendGroupName:"",
      modalName:"添加",
      flag:0,//0：添加；1：修改；2：删除
      title:this.$t('timeTrend.a14'),
      confirmText:this.$t('timeTrend.a15'),
      cancelText:this.$t('timeTrend.a16'),
    };
  },
  // 获取侧边栏数据同时执行虚拟化列表的方法
  async mounted() {
    this.spinning = true;
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();    
    this.spinning = false;
  },
  computed: {
    // 侧边栏的高度计算和padding-top计算
    style: function () {
      if (this.pos !== 0) {
        return `height:${48 * this.arr.length}px;padding-top:${
          this.pos * 48 - 96
        }px`;
      } else {
        return `height:${48 * this.arr.length}px;padding-top:0px`;
      }
    },
  },
  watch: {
    // 监听侧边栏选择的id，并加载页面数据
    id: function (newQuestion, oldQuestion) {
      
      this.getdata();
    },
    p1: function () {
      this.selectList = this.arr.slice(this.p1, this.p2);
    },
    '$route'(to,from){
      this.spinning = true;
      // 进入页面时调用获取页面左侧列表方法
      this.navderList();    
      this.spinning = false;
    },
  },
  //缓存页面勾子函数
  activated(){
    this.getdata();
  },
  methods: {
    async confirm(e) {
      await this.trendGrDelete();
      this.navderList();
    },
    cancel(e) {
    },
    handleClickAdd(){//添加图标点击响应
      this.modalName = this.$t('timeTrend.a11');
      this.flag = 0;
      this.trendGroupName="";
      this.visible = true;      
    },
    handleClickEdit(){//添加图标点击响应
      this.modalName = this.$t('timeTrend.a12');
      this.flag = 1;
      this.trendGroupName=this.nodeName;
      this.visible = true;  
    },
    handleOk(){
      this.visible = false;
      switch(this.flag){
        case 0:
          let schedule = this.list[0].children[0].children;
          let isExist = false;
          for(var i=0;i<schedule.length;i++){
              if(this.trendGroupName == schedule[i].title){ 
                isExist = true;
                break;
              }
          }  
          if(isExist){
            this.$message.info(this.$t('timeTrend.a19')); 
          }
          else
            this.trendGrAdd();
          break;
        case 1:
          this.trendGrUpdate();
          break;
      }      
    } , 
    cancel(){
    },
    trendGrAdd(){
      let par = {
        siteId: this.$route.query.id,
        str: this.trendGroupName,
      };
      insertTrendGrBySite(par)
        .then((res) => {
          this.spinning = false;
          let { data } = res;
          if(data)
            this.$message.info(this.$t('timeTrend.a17'));   
          else     
            this.$message.error(this.$t('timeTrend.a18')); 
          this.navderList();      
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
          this.$message.error(this.$t('timeTrend.a18')); 
        });
    },
    trendGrUpdate(){
      let par = {
        siteId: this.$route.query.id,
        str: this.nodeName,
        strNewName:this.trendGroupName,
        commentId:this.nodeId,
      };
      console.log("trendGrUpdate par:",par);
      updateTrendGrBySite(par)
        .then((res) => {
          this.spinning = false;
          let { data } = res;
          if(data)
            this.$message.info(this.$t('timeTrend.a17'));   
          else     
            this.$message.error(this.$t('timeTrend.a18'));  
          this.navderList();     
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
          this.$message.error(this.$t('timeTrend.a18')); 
        });
    },
    trendGrDelete(){
      let par = {
        siteId: this.$route.query.id,
        commentId: this.nodeId,
      };
      deleteTrendGrBySite(par)
        .then((res) => {
          this.spinning = false;
          let { data } = res;
          if(data)
            this.$message.info(this.$t('timeTrend.a17'));   
          else     
            this.$message.error(this.$t('timeTrend.a18'));   
          this.navderList();     
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
          this.$message.error(this.$t('timeTrend.a18')); 
        });
    },

    // 获取页面左侧列表方法
    navderList() {
      this.spinning = true;
      let name = this.$route.name;
     
      if(name=="VRFAC_FAC_TOLEND_GR" || name=="LIGHTING_FAC_TOLEND_GR"||name=="ENVIRONMENT_FAC_TOLEND_GR"||name=="BSDSAC_FAC_TOLEND_GR"||name=="FACILITIES_FAC_TOLEND_GR")
        this.schType=11; 
     
      if(name=="VRFAC_FAC_TIME_TLEND_GR" || name=="LIGHTING_FAC_TIME_TLEND_GR"||name=="ENVIRONMENT_FAC_TIME_TLEND_GR"||name=="BSDSAC_FAC_TIME_TLEND_GR"||name=="FACILITIES_FAC_TIME_TLEND_GR")
        this.schType=12; 

      //CHW ADD 能源模块添加趋势数据
       console.log('navderList>>'+name);
      if(name=="ENE_TREND_GR")
        this.schType=11; 
      if(name=="ENE_TIME_TREND_GR")
        this.schType=12; 
      //CHW ADD 能源模块添加趋势数据 

      let par = {
        siteId: this.$route.query.id,
        schType: this.schType,
      };
      lightTimetableListKT(par)
        .then((res) => {
          this.spinning = false;
          let { data } = res;
          data.key = "sub1";
          console.log("lightTimetableListKT--加载数据：",data);
          this.list.length=0;
          this.list.push(data);          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });
    },
    // 加载页面数据的方法    
    getdata() {
      this.loading = true;      
      let par = {
        siteId:this.$route.query.id,
        trendGroupId: this.nodeId,
        trendGroupName: this.nodeName
      };  
      this.infoList = new Array();    
      getTrendDeviceInfo(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          // this.infoList.length=0;          
          if(data.binaryInfoList!=null && data.binaryInfoList.length>0){
              for(let i=0;i<data.binaryInfoList.length;i++)
                this.infoList.push(data.binaryInfoList[i]);   
          }         
          if(data.analogInfoList!=null && data.analogInfoList.length>0)
            for(let i=0;i<data.analogInfoList.length;i++)
                this.infoList.push(data.analogInfoList[i]);
          if(data.multiStateInfoList!=null && data.multiStateInfoList.length>0)
            for(let i=0;i<data.multiStateInfoList.length;i++)
                this.infoList.push(data.multiStateInfoList[i]);
          if(data.accumulatorInfoList!=null && data.accumulatorInfoList.length>0)
            for(let i=0;i<data.accumulatorInfoList.length;i++)
                this.infoList.push(data.accumulatorInfoList[i]);
          if(data.calculateInfoList!=null && data.calculateInfoList.length>0)
            for(let i=0;i<data.calculateInfoList.length;i++)
                this.infoList.push(data.calculateInfoList[i]);     
          for (let i = 0; i < this.infoList.length; i++) {
            this.infoList[i].key = i + 1;
          }
          console.log("getTrendDeviceInfo--加载数据有效数据：",this.infoList);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },    
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data) {
      this.id = data;
      let number = this.id.split(",");
      this.nodeId = number[1];
      let schedule = this.list[0].children[0].children;
      this.nodeParentName = this.list[0].children[0].title;
      for(var i=0;i<schedule.length;i++){
          if(this.id == schedule[i].key){
            this.nodeName = schedule[i].title;
            break;
          }
      }            
    },
    
    // 联动控制页跳转至输入点编辑页
    toTmEditorIn() {
      let singnalType = 19;
      let path = '';
      if(this.$route.name.indexOf("BSDSAC_")>=0) {
        path = "/homepage/air-conditionwater/terminalEditor";
      }
      else if(this.$route.name.indexOf("VRFAC_")>=0) {
        path = "/homepage/air-condition/terminalEditor";
      }
      else if(this.$route.name.indexOf("LIGHTING_")>=0) {
        path = "/homepage/light/terminalEditor";
      }
      else if(this.$route.name.indexOf("ENVIRONMENT_")>=0) {
        path = "/homepage/environment/terminalEditor";
      }       
      else if(this.$route.name.indexOf("FACILITIES_")>=0) {
        path = "/homepage/facility/terminalEditor";
      }   
      else if(this.$route.name.indexOf("ENE_")>=0) {
        path = "/homepage/energy/terminalEditor";
      } 
      console.log('path',path);
      const value = this.$route.query.Code;
      console.log('value>>',value);
      this.$router.push({
        path: path,
        query: { id: this.$route.query.id, nodeId: this.nodeId,schType:this.schType,type:singnalType,
                nodeName:this.nodeName,Code:value },
      });
      
    },         
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-navder": navdar,
    "my-headertitle": headerTitle,
    "my-table": deviceTrendTable,
    "my-select": select,
    
  },
};
</script>
<style scoped>
#controllGroupTrend {
  height: 100%;
  display: flex;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;  
}
.top-left{
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;  
}
.icon{  
  margin-right: 10px;
}
.main {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.info-hidden {
  display: flex;
  height: 60px;
  align-items: center;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
button {
  /* width: 120px; */
  margin: 0px 10px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.d {
  /* display: flex; */
  position: relative;
}
.c {
  position: absolute;
  width: 600px;
}
</style>